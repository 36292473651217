import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { firebaseAuth } from "../../../providers/AuthProvider";
import firebase from "firebase/compat/app";
import "firebase/storage";
import { db } from "../../../firebase";
import "firebase/functions";
import { DeleteIcon, DLAbbrDesMap } from "../../../Constants";
import "../../../assets/css/groups.css";
import { EventDataContext } from "../EventDataContext";
import { toast } from "react-toastify";
import SignatureCanvas from "react-signature-canvas";
import "../../../assets/css/registrations.css";
import DOMPurify from "dompurify";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';

import BarcodeReader from "react-barcode-reader";
import moment from "moment";
// import * as Sentry from "@sentry/react";
import Dropzone from 'react-dropzone';
import Moment, { relativeTimeRounding } from 'moment';
import { StatesList } from "../../../Constants";
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';

const Registration = () => {
  const { id } = useParams();
  const storage = firebase.storage().ref();
  const history = useNavigate();
  const { user } = useContext(firebaseAuth);
  const { eventData, setEventData } = useContext(EventDataContext);
  const [dealersSelected, setDealersSelected] = useState(
    eventData.dealersSelected
  );
  const [uploadInfo, setUploadInfo] = useState(null);
  const [adyenLoaded, setAdyenLoaded] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);

  const [savingSignature, setSavingSignature] = useState(false);
  const [sendEmailCheck, setSendEmailCheck] = useState(false);
  const sigCanvas = React.createRef();
  const [editRegistration, setEditRegistration] = useState(null);
  const [completeWaiver, setCompleteWaiver] = useState(false);
  const [previewEmailHtml, setPreviewEmailHtml] = useState(null);
  const [waiverUrl, setWaiverUrl] = useState();
  const [waiverSigUrl, setWaiverSigUrl] = useState();
  var parseDriverLicense = (txt) => {
    let lines = txt.split("\n");
    let abbrs = Object.keys(DLAbbrDesMap);
    let map = {};
    lines.forEach((line, i) => {
      let abbr;
      let content;
      if (i === 0) {
        abbr = "DAQ";
        content = line.substring(line.indexOf(abbr) + 3);
      } else {
        abbr = line.substring(0, 3);
        content = line.substring(3).trim();
      }
      if (abbrs.includes(abbr)) {
        map[abbr] = {
          description: DLAbbrDesMap[abbr],
          content: content,
        };
      }
    });
    return map;
  };
  const arraySearch = (array, keyword) => {
    const searchTerm = keyword.toLowerCase();
    if (array.length === 0)
      return false;
    let arrayIndex = array.findIndex(value => value && value.fieldType?.toLowerCase() === searchTerm);

    return arrayIndex;
  }
  const handleScan = (d) => {
    console.log(d.indexOf("porsche-ezentrum"));
    if (d.indexOf("porsche-ezentrum") >= 0) {
      let d_arr = d.split("/");
      let regId = d_arr[d_arr.length - 1];
      console.log(regId);
      history("/manage/registration/" + regId);
      history.go();
    } else {
      if (d.indexOf("ANSI") < 0) return;
      let dlData = parseDriverLicense(d);
      if (editRegistration) {
        let tmpReg = editRegistration;
        tmpReg.firstName = dlData["DAC"]?.content;
        tmpReg.lastName = dlData["DCS"]?.content;
        tmpReg.address1 = dlData["DAG"]?.content;
        tmpReg.city = dlData["DAI"]?.content;
        tmpReg.state = dlData["DAJ"]?.content;
        tmpReg.postal = dlData["DAK"]?.content?.substring(0, 5);
        setEditRegistration({
          ...editRegistration,
          firstName: tmpReg.firstName,
          lastName: tmpReg.lastName,
          address1: tmpReg.address1,
          city: tmpReg.city,
          state: tmpReg.state,
          postal: tmpReg.postal,
        });
        if (!eventData.dealersSelected || eventData.dealersSelected.length === 0)
          updateDealerByPostal(tmpReg.postal);
        window.scrollTo(0, 0);
      }
    }
  };
  const handleScanError = (e) => {
    console.log(e);
  };
  const clearCanvas = () => { sigCanvas.current.clear(); setWaiverSigUrl(null) }
  const [scr, setScr] = useState({
    width: window.innerWidth > 600 ? 600 : window.innerWidth - 30,
    height: window.innerWidth > 600 ? 240 : window.innerWidth * 0.4 - 30,
  });
  const sendWaiver = () => {
    alert("send waiver notification");
  };
  const deleteWaiver = () => {
    // setEditRegistration({ ...editRegistration, waiver: null,waiverSignature:null });
    setWaiverUrl(null);
    setWaiverSigUrl(null);
    sigCanvas.current.clear();
    deleteFile("/waivers/", editRegistration.id + ".pdf");
    deleteFile("/waivers/", editRegistration.id + ".jpg");
    let reg = editRegistration;
    reg.hasWaiver = false;
    saveRegistration(reg, "none");
    setCompleteWaiver(false);
  };
  const deleteFile = (pathToFile, fileName) => {

    const ref = firebase.storage().ref(pathToFile);
    const childRef = ref.child(fileName);
    console.log(childRef);
    if (childRef) {

      childRef.delete().catch((error) => {
        console.log(error);
      });

    }

  };
  const saveCanvas = async () => {
    if (sigCanvas.current.isEmpty()) return;

    storage
      .child("/waivers/" + editRegistration.id + ".jpg")
      .putString(
        sigCanvas.current.getTrimmedCanvas().toDataURL("image/jpg"),
        "data_url"
      )
      .then((r) => {
        r.ref.getDownloadURL().then((url) => {
          setWaiverSigUrl(url);
        })
          .catch((e) => {
            setSavingSignature(false);

            // Sentry.captureException(e);
          })
          .finally(() => {
            setSavingSignature(false);

          });
      })
      .catch((e) => {
        setSavingSignature(false);

        // Sentry.captureException(e);
      });
  };
  const newReg = () => {
    setEditRegistration({
      status: eventData.defaultRegStatus
        ? eventData.defaultRegStatus
        : "Registered",
      eventId: eventData.id,
      id: db.collection("registrations").doc().id,
    });
  }
  const guestReg = (id, parentId) => {
    setEditRegistration({
      status: eventData.defaultRegStatus
        ? eventData.defaultRegStatus
        : "Registered",
      isGuest: true,
      parentId: parentId,
      eventId: eventData.id,
      id: id,
    });
  }
  const onDrop = async (acceptedFiles, rejectedFiles) => {
    if (eventData?.allowUpload && acceptedFiles.length > 0) {
      await acceptedFiles.map(async (f, index) => {
        firebase.storage()
          .ref("/submissions/" + editRegistration.id + "/" + f.path)
          .put(f).then(() => {
            updateUploads();
          });
      });
    }
    let x = rejectedFiles?.forEach((item) => {
      if (item.file?.name && item.errors[0].message)
        alert(item.file?.name + ":\n" +
          item.errors[0].message + "\n\n");
    });
  }
  const updateUploads = async () => {
    if (eventData.allowUpload) {
      var storageRef = firebase.storage().ref("/submissions/" + id + "/");
      let tmpFiles = [];
      let i = 0;
      storageRef.listAll()
        .then(function (result) {
          result.items.forEach(function (fileRef) {
            fileRef.getDownloadURL().then(function (url) {
              tmpFiles.push({ name: fileRef.name, url: url });

              i++;
              if (i == result.items.length)
                setUploadInfo(tmpFiles);

            }).catch(function (error) {
              // Handle any errors
            });
          });
        }).catch(function (error) {
          // Handle any errors
        });
    }
  }
  const handleOnChange = (state, component) => {
    console.log(state.isValid);
    console.log(state.data);
    // state.isValid // True or false. Specifies if all the information that the shopper provided is valid.
    // state.data // Provides the data that you need to pass in the `/payments` call.
    // component // Provides the active component instance that called this event.
  }
  const configuration = {
    locale: "en_US",
    environment: "test",
    clientKey: "test_7IHEI5LYLVG4HLEQRG562U7A6EUPO2GZ",
    onChange: handleOnChange
  };
  async function adyenInit() {
    const checkout = await AdyenCheckout(configuration)
      .then(checkout => {
        checkout.create('securedfields', {
          // Optional configuration
          type: 'card',
          brands: ['mc', 'visa', 'amex', 'bcmc', 'diners','discover','maestro'],
          styles: {
            base: {

            },
            error: {
              color: 'red'
            },
            validated: {
              color: 'green'
            },
            placeholder: {
              color: '#d8d8d8'
            }
          },
          // Events
          onChange: (r) => {
            if (r.isValid) {
              setPaymentMethod(r.data.paymentMethod);
            } else {
              setPaymentMethod(null);
            }
          },
          onValid: function () { },
          onLoad: function () { },
          onConfigSuccess: function () { },
          onFieldValid: function () { },
          onBrand: function () { },
          onError: function () { },
          onFocus: function () { },
          onBinValue: function (bin) { },
          onBinLookup: function (callbackObj) { }
        }).mount('#customCard-container');
      });
  }
  const loadAdyen = async () => {
    // await adyenInit()
    // setAdyenLoaded(true);
  }
  useEffect(() => {
    if (!checkAuth()) return;
    if (!checkEventData()) return;

    if (!id) {
      // history('/manage/registrations');
      newReg();
    } else {

      db.collection("registrations").doc(id).get()
        .then(async (result) => {
          let eData = eventData;
          console.log(eventData);
          if (!eventData || eventData.id !== result.data().eventId) {
            let qs = await db
              .collection("events")
              .where("id", "==", result.data().eventId)
              .get();

            if (qs.docs.length > 0) {
              eData = qs.docs[0].data();
              eData.id = qs.docs[0].id;
              console.log(eData);
              localStorage.setItem("eventData", JSON.stringify(eData));
              setEventData(eData);

            } else {

              // setLookupComplete(false);
            }


          }
          if (eData.dataFields?.dealer?.visible && (!eData.dealersSelected || eData.dealersSelected.length === 0))
            updateDealerByPostal(result.data()?.postal);
          let tmpResult = result.data();
          console.log('-----');
          console.log(tmpResult);

          if (tmpResult.createdAt?.seconds) {
            tmpResult.createdAt = tmpResult.createdAt.seconds * 1000 + tmpResult.createdAt.nanoseconds / 1000000
          }
          if (tmpResult.updatedAt?.seconds) {
            tmpResult.updatedAt = tmpResult.updatedAt.seconds * 1000 + tmpResult.updatedAt.nanoseconds / 1000000
          }
          if (tmpResult.expiration?.seconds) {
            tmpResult.expiration = tmpResult.expiration.seconds * 1000 + tmpResult.expiration.nanoseconds / 1000000
          }
          console.log(tmpResult);
          console.log(id);
          console.log('-----');
          setEditRegistration(tmpResult);

          if (!tmpResult?.adyenRef && arraySearch(eData?.additionalFields, 'CommerceItem') >= 0) {
            loadAdyen();
          }
        })
        .catch((e) => {
          // Sentry.captureException(e);

        });
    }
    updateUploads();
    DOMPurify.setConfig({ ADD_ATTR: ['target'] });


  }, []);
  useEffect(() => {
    if (editRegistration && editRegistration.hasWaiver && !waiverUrl) {
      storage
        .child("/waivers/" + editRegistration?.id + ".pdf")
        .getDownloadURL()
        .then((url) => {
          setWaiverUrl(url);
        })
        .catch((e) => {
          // Sentry.captureException(e);
        });
    }
    if (editRegistration && editRegistration.hasWaiver && !waiverSigUrl) {
      storage
        .child("/waivers/" + editRegistration?.id + ".jpg")
        .getDownloadURL()
        .then((url) => {
          setWaiverSigUrl(url);
        })
        .catch((e) => {
          // Sentry.captureException(e);
        });
    }
  }, [editRegistration]);
  const deleteRegistration = () => {
    try {
      deleteFile("/waivers/", editRegistration.id + ".pdf");
      deleteFile("/waivers/", editRegistration.id + ".jpg");
    } catch (error) {
      console.log(error);
    }
    db.collection("registrations").doc(editRegistration.id).delete();
    setEditRegistration(null);
    setCompleteWaiver(false);
    setWaiverSigUrl(null);
    setWaiverUrl(null);
    clearCanvas();
    history("/manage/registrations");
  };
  const handleDeletePaymentLink = (e) => {
    e.preventDefault();
    var prod = eventData?.prodPayment;
    var deletePaymentLink = firebase.functions().httpsCallable("app/deletePaymentLink");
    deletePaymentLink({
      reg: editRegistration,
      prodPayment: prod
    })
      .then((result) => {
        console.log(result);
        if (result.data.error) {
          alert(result.data.message);
          return;
        }
        setEditRegistration({ ...editRegistration, adyenLink: null, adyenLinkId: null, adyenLinkExpireDate: null })
      })
      .catch((error) => {
        alert("There was a problem reaching the service to create your payment link, try again later.");
      });
  };
  const handleWaivedPaymentLink = (e) => {
    e.preventDefault();
    setEditRegistration({ ...editRegistration, adyenPaymentStatus: 'Waived' });
  };
  const handleRefundedPaymentLink = (e) => {
    e.preventDefault();
    setEditRegistration({ ...editRegistration, adyenPaymentStatus: 'Refunded' });
    alert('Make sure to process refund in the Adyen system.  This is only a flag to show as refunded in eZentrum.');
  };
  const handleCreatePaymentLink = (e) => {
    e.preventDefault();
    if (!editRegistration.updatedAt) {
      alert('Save and close registration, then reload before creating payment link.')
      return;
    }


    var createPaymentLink = firebase.functions().httpsCallable("app/createPaymentLink");
    createPaymentLink({
      reg: editRegistration,
      prodPayment: eventData?.prodPayment,
      paymentTax: eventData?.paymentTax,
      additionalFields: eventData.additionalFields
    })
      .then((result) => {
        console.log(result);
        if (result.data.error) {
          alert(result.data.message);
          return;
        }
        setEditRegistration({ ...editRegistration, adyenLink: result.data.adyenLink, adyenLinkId: result.data.adyenLinkId, adyenLinkExpireDate: result.data.adyenLinkExpireDate, updatedAt: result.data.updatedAt })
      })
      .catch((error) => {
        alert("There was a problem reaching the service to create your payment link, try again later.");

      });
  };
  const handleSubmitNone = (e) => {
    e.preventDefault();
    handleSubmit("none");
  };
  const handleSubmitAndClose = (e) => {
    e.preventDefault();
    handleSubmit("close");
  };
  const handleSubmitAndNew = (e) => {
    e.preventDefault();
    handleSubmit("new");
  };
  const handleCheckInAndNew = (e) => {
    e.preventDefault();
    handleSubmit("new", true);
  };
  const handleSubmitAndGuest = (e) => {
    e.preventDefault();
    handleSubmit("guest");
  };
  const handleCheckInAndGuest = (e) => {
    e.preventDefault();
    handleSubmit("guest", true);
  };
  const handleCheckInAndClose = (e) => {
    e.preventDefault();
    handleSubmit("close", true);
  };
  const handleSubmit = (action, checkin) => {
    if (savingSignature) {
      alert('Waiver is still being saved, please try again.');
      return;
    }
    if (
      eventData?.dataFields?.firstName?.visible &&
      eventData?.dataFields?.firstName?.required &&
      !editRegistration?.firstName
    ) {
      toast.error("First name is required.");
      return;
    }
    if (
      eventData?.dataFields?.lastName?.visible &&
      eventData?.dataFields?.lastName?.required &&
      !editRegistration.lastName
    ) {
      toast.error("Last name is required.");
      return;
    }
    if (
      eventData?.dataFields?.postal?.visible &&
      eventData?.dataFields?.postal?.required &&
      !editRegistration.postal
    ) {
      toast.error("Postal code is required.");
      return;
    }
    if (
      editRegistration.dealer_optin &&
      !editRegistration.purchaseTimeFrame
    ) {
      toast.error("Purchase Time Frame is required.");
      return;
    }
    if (
      editRegistration.dealer_optin &&
      !editRegistration.modelInterest
    ) {
      toast.error("Model Interest is required.");
      return;
    }
    if (
      eventData?.dataFields?.email?.visible &&
      eventData?.dataFields?.email?.required &&
      !editRegistration.email
    ) {
      toast.error("Email is required.");
      return;
    }
    if (sendEmailCheck && !editRegistration.email) {
      toast.error("Email is required to send confirmation email.");
      return;
    }
    let reg = editRegistration;
    let ts = firebase.firestore.FieldValue.serverTimestamp()
    // if (!reg.createdAt) {
    // reg.createdAt = ts;
    // reg.createdDate = moment(reg.createdAt.toDate()).format("MM/DD/YYYY h:mm A");
    // }
    // reg.updatedAt = ts;
    if (checkin) reg.status = "Checked-In";
    // console.log(dealersSelected[0].code);
    if (eventData.endDate) {
      console.log(eventData.endDate);
      console.log(moment(eventData.endDate));
      let exp = moment(eventData.endDate).clone().add(91, 'days');
      console.log(exp.format("MM/DD/YYYY h:mm A"));
      reg.expiration = exp.format("MM/DD/YYYY h:mm A");
    }
    reg.email = reg.email?.toLowerCase();
    if (dealersSelected && dealersSelected?.length === 1) reg.dealer = dealersSelected[0].code;
    if (eventData.allowWaivers) {
      if (!reg.hasWaiver) {
        if (waiverSigUrl) {
          const doc = new jsPDF("p", "px", "a4", true);

          const input = document.getElementById("waiver-print");
          console.log(input.getBoundingClientRect().width)
          var clone = input.cloneNode(true);
          let w = input.getBoundingClientRect().width;
          clone.id = "clone";
          console.log(clone.getBoundingClientRect().width);

          clone.style.cssText = "height:unset !important;";
          document.body.append(clone);
          // let h = clone.getBoundingClientRect().height + 'px';
          let pWidth = doc.internal.pageSize.width;
          let margin = 15;
          let scale = (pWidth - margin * 2) / w;
          doc.html(clone, {
            x: margin,
            y: margin,
            html2canvas: {
              useCORS: true,
              allowTaint: false,
              scale: scale,
            },
            callback: async () => {
              // doc.save('test.pdf');
              clone.remove();
              storage
                .child("/waivers/" + reg.id + ".pdf")
                .put(doc.output("blob"), {
                  contentType: "application/pdf",
                })
                .then((r) => {
                  r.ref.getDownloadURL().then((url) => {
                    setWaiverUrl(url);
                  });
                })
                .catch((e) => {
                  // Sentry.captureException(e);
                });
              let waiver = reg.id + ".pdf";
              reg.hasWaiver = true;
              saveRegistration(reg, action);
            },
          });
        } else {
          saveRegistration(reg, action);
        }
      } else {
        saveRegistration(reg, action);
      }
    } else {
      saveRegistration(reg, action);
    }
    // const dataURL = sigCanvas.current.toDataURL();
    // setSignatureResult(dataURL)
    // onSave(dataURL)
    // setIsVisible(!isVisible)
  };
  const previewEmail = async () => {

    if (eventData.allowConfirmationEmail && editRegistration.email) {
      var sendEmail = firebase
        .functions()
        .httpsCallable("app/sendConfirmationEmail");
      sendEmail({ reg: editRegistration, eventData: eventData, preview: true })
        .then((result) => {
          if (result?.data.error) {
            toast.warning(result?.data.message);
            return;
          }
          else {
            setPreviewEmailHtml(result?.data.message);

          }
        })
        .catch((e) => {
          // Sentry.captureException(e);
          toast.error(e.message);
        });
    }
  }
  const saveRegistration = async (reg, action) => {

    clearCanvas();
    //if dup email allowed or no dup email
    //if allow dealer and 1 matches or at least there if none or more
    let guestId = db.collection("registrations").doc().id

    if (action == "guest") {
      reg.guestId = guestId;
    }
    reg.adminSave = true;
    console.log(reg);
    console.log('test');
    var saveReg = firebase.functions().httpsCallable("app/saveReg");

    let result = await saveReg({
      reg: reg,
      eventData: eventData,
      guestReg: null
    });
    if (result.data.error) {
      alert(result.data.message);
      return;
    }
    // alert('saved');
    // db.collection("registrations").doc(reg.id).set(reg);

    if (sendEmailCheck && eventData.allowConfirmationEmail && reg.email) {
      var sendEmail = firebase
        .functions()
        .httpsCallable("app/sendConfirmationEmail");
      sendEmail({ reg: reg, eventData: eventData })
        .then((result) => {
          if (result?.data.error) {
            toast.warning(result?.data.message);
            return;
          }
          setSendEmailCheck(false);
        })
        .catch((e) => {
          // Sentry.captureException(e);
          toast.error(e.message);
        });
    }
    toast.success("Saved");

    if (action === "close") {
      history("/manage/registrations");
    } else if (action === "new") {
      setCompleteWaiver(false);
      history('/manage/registration');
      newReg();
    } else if (action === "guest") {
      setCompleteWaiver(false);
      history('/manage/registration');
      guestReg(guestId, reg.id);
    }
  };
  const handleChange = (e) => {
    // if(e.target.value.indexOf("@ANSI") > -1)
    //   {
    //     setEditRegistration({
    //       ...editRegistration,
    //       [e.target.name]: "",
    //     });
    //     handleScan(e.target.value);
    //   }
    setEditRegistration({
      ...editRegistration,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeCommerceItem = async (e, cost) => {
    let formatterUSD = new Intl.NumberFormat('en-US', {
      roundingMode: 'halfCeil',
      style: 'currency',
      currency: 'USD'
    });
    // let qty = +e.target.value;
    // let subCost,taxCost,totalCost;
    // if(tax) {
    // subCost = formatterUSD.format(cost * qty);
    // taxCost = formatterUSD.format(cost * qty * (tax / 100));
    // totalCost = formatterUSD.format(cost * qty * (1 + tax / 100));
    // } else {
    //   subCost = formatterUSD.format(cost * qty);
    //   taxCost = formatterUSD.format(0);
    //   totalCost = formatterUSD.format(cost * qty);
    // }



    let tmpSub = 0;
    let tmpItem = +e.target.value * cost;
    if (eventData?.additionalFields) {
      eventData.additionalFields.map((dataField, index) => {
        if (dataField.fieldType === "CommerceItem") {

          let qty = 0;
          if (e.target.name === dataField.fieldName)
            qty = +e.target.value;
          else
            qty = +editRegistration[dataField.fieldName];
          if (qty)
            tmpSub += qty * +dataField.fieldCost;



        }
      });
    }
    // let qty = +e.target.value;
    let tax = +eventData?.paymentTax;
    let subCost, taxCost, totalCost;
    if (tax) {
      subCost = formatterUSD.format(tmpSub);
      taxCost = formatterUSD.format(tmpSub * (tax / 100));
      totalCost = formatterUSD.format(tmpSub * (1 + tax / 100));
    } else {
      subCost = formatterUSD.format(tmpSub);
      taxCost = formatterUSD.format(0);
      totalCost = formatterUSD.format(tmpSub);
    }

    setEditRegistration({
      ...editRegistration,
      // [e.target.name]: e.target.value, [e.target.name + "Cost"]: totalCost, [e.target.name + "TaxCost"]: taxCost, [e.target.name + "SubCost"]: subCost
      [e.target.name]: e.target.value, [e.target.name + "Cost"]: formatterUSD.format(tmpItem), "taxCost": taxCost, "subCost": subCost, "totalCost": totalCost

    });

    // if (!adyenLoaded && paymentType === 'payment') {

    //   await adyenInit()
    //   setAdyenLoaded(true);

    // }
  };
  const handlePostal = (e) => {
    setEditRegistration({
      ...editRegistration,
      [e.target.name]: e.target.value,
    });
    if (!eventData.dealersSelected || eventData.dealersSelected.length === 0) {
      updateDealerByPostal(e.target.value);
    }
  };
  const updateDealerByPostal = (pc) => {
    if (pc) {
      setDealersSelected([]);
      db.collection("locator")
        .where("postal", "==", pc)
        .orderBy("dealerDistance")
        .limit(5)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach((doc, i) => {
            db.collection("dealers")
              .where("code", "==", doc.data().code)
              .limit(1)
              .get()
              .then((qs) => {
                if (qs.docs.length > 0) {
                  if (qs.docs[0]) {
                    setDealersSelected((dealersSelected) => [
                      ...dealersSelected,
                      qs.docs[0].data(),
                    ]);
                  }
                }
              });
          });
        })
        .catch(function (e) {
          // Sentry.captureException(e);
        });
    }
  };
  const downloadWaiver = async (e, waiverUrl) => {
    e.preventDefault();
    if (waiverUrl) {
      window.fetch(waiverUrl).then((res) => {
        res.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = "waiver_" + editRegistration.id + ".pdf"; // the filename you want
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        });
      });
    }
  };
  useEffect(() => {
    checkAuth();
  }, [user]);
  useEffect(() => {
    checkEventData();
  }, [eventData]);
  const checkEventData = () => {
    if (!eventData || !eventData.id) {
      toast.error(
        "Select to edit or view registrations for an event before continuing.."
      );
      history("/manage/events");
      return false;
    } else {
      return true;
    }
  };
  const checkAuth = () => {
    if (!user.isAuthenticated) {
      history("/manage/sign-in");
      return false;
    } else {
      return true;
    }
  };
  const switchReg = (id) => {
    db.collection("registrations").doc(id).get()
      .then((result) => {
        history('/manage/registration/' + id);
        if (eventData.dataFields?.dealer?.visible)
          updateDealerByPostal(result.data()?.postal);

        let tmpResult = result.data();
        if (tmpResult.createdAt.seconds) {
          tmpResult.createdAt = tmpResult.createdAt.seconds * 1000 + tmpResult.createdAt.nanoseconds / 1000000
        }
        if (tmpResult.updatedAt.seconds) {
          tmpResult.updatedAt = tmpResult.updatedAt.seconds * 1000 + tmpResult.updatedAt.nanoseconds / 1000000
        }
        setEditRegistration(tmpResult);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (

    <div className="container">

      <h1>Edit {editRegistration?.isGuest ? "Guest " : ""}Registration</h1>
      <div>{editRegistration?.id} {editRegistration?.passcodeUsed ? " | " + editRegistration?.passcodeUsed : ""}</div>
      {editRegistration?.guestId &&
        <button className="btn btn-link p-0" onClick={() => { switchReg(editRegistration.guestId); }}>View guest registration</button>
      }
      {editRegistration?.parentId &&
        <button className="btn btn-link p-0" onClick={() => { switchReg(editRegistration.parentId); }}>View primary registration</button>
      }
      <BarcodeReader onError={handleScanError} onScan={handleScan} />

  
      <div className="py-2"></div>
      <div className="row">
        <div className={"col-12 col-lg-8 col-xl-6"}>
          <div
            className={
              eventData.dataFields?.company?.visible ? "form-group" : "d-none"
            }
          >
            <label className="form-label" htmlFor="company">
              Company{" "}
              {eventData.dataFields?.company?.visible &&
                eventData.dataFields?.company?.required
                ? "*"
                : ""}
            </label>
            <input
              type="text"
              id="company"
              name="company"
              value={editRegistration?.company || ""}
              onChange={handleChange}
              className="form-control"
              required={
                eventData.dataFields?.company?.visible &&
                  eventData.dataFields?.company?.required
                  ? true
                  : false
              }
            />
          </div>
          <div className="row">
            <div
              className={
                eventData.dataFields?.title?.visible ? "col-md-auto" : "d-none"
              }
            >
              <div className={"form-group"}>
                <label className="form-label" htmlFor="title">
                  Title{" "}
                  {eventData.dataFields?.title?.visible &&
                    eventData.dataFields?.title?.required
                    ? "*"
                    : ""}
                </label>
                <select
                  className="form-control"
                  id="title"
                  name="title"
                  onChange={(e) => {
                    setEditRegistration({
                      ...editRegistration,
                      title: e.target.value,
                    });
                  }}
                  value={editRegistration?.title}
                  required={
                    eventData.dataFields?.title?.visible &&
                      eventData.dataFields?.title?.required
                      ? true
                      : false
                  }
                >
                  <option value=""></option>
                  <option value="1">Mr.</option>
                  <option value="3">Ms.</option>
                  <option value="2">Mrs.</option>
                  <option value="4">Dr.</option>
                </select>
              </div>
            </div>
            <div
              className={
                eventData.dataFields?.firstName?.visible ? "col-md" : "d-none"
              }
            >
              <div className="form-group">
                <label className="form-label" htmlFor="firstName">
                  First Name{" "}
                  {eventData.dataFields?.firstName?.visible &&
                    eventData.dataFields?.firstName?.required
                    ? "*"
                    : ""}
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={editRegistration?.firstName || ""}
                  onChange={handleChange}
                  className="form-control"
                  required={
                    eventData.dataFields?.firstName?.visible &&
                      eventData.dataFields?.firstName?.required
                      ? true
                      : false
                  }
                />
              </div>
            </div>
            <div
              className={
                eventData.dataFields?.lastName?.visible ? "col-md" : "d-none"
              }
            >
              <div className="form-group">
                <label className="form-label" htmlFor="lastName">
                  Last Name{" "}
                  {eventData.dataFields?.lastName?.visible &&
                    eventData.dataFields?.lastName?.required
                    ? "*"
                    : ""}
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={editRegistration?.lastName || ""}
                  onChange={handleChange}
                  className="form-control"
                  required={
                    eventData.dataFields?.lastName?.visible &&
                      eventData.dataFields?.lastName?.required
                      ? true
                      : false
                  }
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className={
                eventData.dataFields?.email?.visible ? "col-md" : "d-none"
              }
            >
              <div className="form-group">
                <label className="form-label" htmlFor="email">
                  Email Address{" "}
                  {eventData.dataFields?.email?.visible &&
                    eventData.dataFields?.email?.required
                    ? "*"
                    : ""}
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  value={editRegistration?.email || ""}
                  onChange={handleChange}
                  className="form-control"
                  required={
                    eventData.dataFields?.email?.visible &&
                      eventData.dataFields?.email?.required
                      ? true
                      : false
                  }
                />
              </div>
            </div>
            <div
              className={
                eventData.dataFields?.phone?.visible ? "col-md" : "d-none"
              }
            >
              <div className="form-group">
                <label className="form-label" htmlFor="phone">
                  Phone Number{" "}
                  {eventData.dataFields?.phone?.visible &&
                    eventData.dataFields?.phone?.required
                    ? "*"
                    : ""}
                </label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  value={editRegistration?.phone || ""}
                  onChange={handleChange}
                  className="form-control"
                  required={
                    eventData.dataFields?.phone?.visible &&
                      eventData.dataFields?.phone?.required
                      ? true
                      : false
                  }
                />
              </div>
            </div>
          </div>
          <div
            className={
              eventData.dataFields?.address1?.visible ? "form-group" : "d-none"
            }
          >
            <label className="form-label" htmlFor="address1">
              Address 1{" "}
              {eventData.dataFields?.address1?.visible &&
                eventData.dataFields?.address1?.required
                ? "*"
                : ""}
            </label>
            <input
              type="text"
              id="address1"
              name="address1"
              value={editRegistration?.address1 || ""}
              onChange={handleChange}
              className="form-control"
              required={
                eventData.dataFields?.address1?.visible &&
                  eventData.dataFields?.address1?.required
                  ? true
                  : false
              }
            />
          </div>
          <div
            className={
              eventData.dataFields?.address2?.visible ? "form-group" : "d-none"
            }
          >
            <label className="form-label" htmlFor="address2">
              Address 2{" "}
              {eventData.dataFields?.address2?.visible &&
                eventData.dataFields?.address2?.required
                ? "*"
                : ""}
            </label>
            <input
              type="text"
              id="address2"
              name="address2"
              value={editRegistration?.address2 || ""}
              onChange={handleChange}
              className="form-control"
              required={
                eventData.dataFields?.address2?.visible &&
                  eventData.dataFields?.address2?.required
                  ? true
                  : false
              }
            />
          </div>
          <div className="row">
            <div
              className={
                eventData.dataFields?.city?.visible ? "col-md-6" : "d-none"
              }
            >
              <div className="form-group">
                <label className="form-label" htmlFor="city">
                  City{" "}
                  {eventData.dataFields?.city?.visible &&
                    eventData.dataFields?.city?.required
                    ? "*"
                    : ""}
                </label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  value={editRegistration?.city || ""}
                  onChange={handleChange}
                  className="form-control"
                  required={
                    eventData.dataFields?.city?.visible &&
                      eventData.dataFields?.city?.required
                      ? true
                      : false
                  }
                />
              </div>
            </div>

            <div
              className={
                eventData?.dataFields?.state?.visible
                  ? "col-md-2"
                  : "d-none"
              }
            >
              <div className="form-group">
                <label className="form-label" htmlFor="state">
                  State{" "}
                  {eventData?.dataFields?.state?.visible &&
                    eventData?.dataFields?.state?.required
                    ? "*"
                    : ""}
                </label>
                <select
                  name="state"
                  id="state"
                  defaultValue={editRegistration?.state}
                  value={editRegistration?.state}

                  onChange={handleChange}
                  className="form-control"
                  required={
                    eventData?.dataFields?.state?.visible &&
                      eventData?.dataFields?.state?.required
                      ? true
                      : false
                  }
                >
                  <option value=""></option>
                  {StatesList?.map((usState, index) => (
                    <option key={index} value={usState.abbreviation}>
                      {usState.abbreviation}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div
              className={
                eventData.dataFields?.postal?.visible ? "col-md-4" : "d-none"
              }
            >
              <div className="form-group">
                <label className="form-label" htmlFor="postal">
                  Postal Code{" "}
                  {eventData.dataFields?.postal?.visible &&
                    eventData.dataFields?.postal?.required
                    ? "*"
                    : ""}
                </label>
                <input
                  type="text"
                  id="postal"
                  name="postal"
                  value={editRegistration?.postal || ""}
                  onChange={handlePostal}
                  className="form-control"
                  required={
                    eventData.dataFields?.postal?.visible &&
                      eventData.dataFields?.postal?.required
                      ? true
                      : false
                  }
                />
              </div>
            </div>
          </div>

          <div className="row">
            {eventData?.additionalFields?.map((dataField, index) => (
              <>
                {dataField.fieldType == "Textblock" ? (
                  <div className="col-12">
                    <div className="pb-2"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          dataField?.textCopy
                        ),
                      }}
                    ></div>
                  </div>
                ) : (<></>)}
                {dataField.fieldType == "Textbox" ? (
                  <div className={dataField.fieldFullRow ? "col-md-12" : (dataField.fieldColumns ? "col-md-" + dataField.fieldColumns : "col-md-" + 6) + " " + (dataField.fieldColumnsMobile ? "col-" + dataField.fieldColumnsMobile : "col-" + 12)}>

                    <div className="form-group">
                      <label className="form-label" htmlFor="dlexpiration">
                        {dataField.fieldLabel}{" "}
                        {dataField.required
                          ? "*"
                          : ""}
                      </label>
                      <input

                        id={dataField.fieldName}
                        name={dataField.fieldName}
                        value={editRegistration ? editRegistration[dataField.fieldName] : ""}
                        onChange={handleChange}
                        className="form-control"
                        required={
                          dataField.required
                            ? true
                            : false
                        }
                      />
                    </div>

                  </div>) : (<></>)}



                {/* {dataField.fieldType == "CommerceItem" ? (
                            <div className={dataField.fieldFullRow ? "col-md-12" : "col-md-6"}>
                              <div className="form-group">
                                <label className="form-label" htmlFor={dataField.fieldName}>
                                  {dataField.fieldLabel}{" "}
                                  {dataField.required
                                    ? "*"
                                    : ""} ({dataField.fieldCost})
                                </label>
                                <div className="pb-2">{dataField.fieldDescription}</div>
                                <input
                                  type="number"
                                  id={dataField.fieldName}
                                  name={dataField.fieldName}
                                  min={dataField.fieldMin}
                                  max={dataField.fieldMax}
                                  step="1"
                                  value={editRegistration ? editRegistration[dataField.fieldName] : ""}
                                  onChange={(e) => { handleChangeCommerceItem(e,dataField.fieldCost,dataField.fieldPayment,dataField.fieldTax)}}
                                  className="form-control"
                                  required={
                                    dataField.required
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                              {editRegistration && editRegistration[dataField.fieldName] ? (

<div className="py-2">
  <div>Total Due: {editRegistration[dataField.fieldName + "Cost"]} / plus tax</div>
</div>

) : (<></>)}
                            </div>) : (<></>)} */}
                {dataField.fieldType == "CommerceItem" ? (<>
                  <div className={dataField.fieldFullRow ? "col-md-12" : (dataField.fieldColumns ? "col-md-" + dataField.fieldColumns : "col-md-" + 6) + " " + (dataField.fieldColumnsMobile ? "col-" + dataField.fieldColumnsMobile : "col-" + 12)}>                    <div className="form-group">
                    <label className="form-label" htmlFor={dataField.fieldName}>
                      {dataField.fieldLabel}{" "}
                      {dataField.required
                        ? "*"
                        : ""}
                    </label>
                    <div className="pb-2"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          dataField?.fieldDescription
                        ),
                      }}
                    ></div>
                    <div className="d-flex align-items-center">
                      {/* <input
                        
                        disabled={editRegistration?.adyenRef || editRegistration?.adyenLink}
                        type="number"
                        id={dataField.fieldName}
                        name={dataField.fieldName}
                        min={dataField.fieldMin}
                        max={dataField.fieldMax}
                        step="1"
                        value={editRegistration ? editRegistration[dataField.fieldName] : ""}
                        onChange={(e) => { handleChangeCommerceItem(e, dataField.fieldCost) }}
                        className="form-control"
                        required={
                          dataField.required
                            ? true
                            : false
                        }
                      />  */}
                      <select
                        disabled={editRegistration?.adyenRef || editRegistration?.adyenLink}

                        className="form-control commerce-item"
                        id={dataField.fieldName}
                        name={dataField.fieldName}
                        value={editRegistration ? editRegistration[dataField.fieldName] : ""}
                        onChange={(e) => { handleChangeCommerceItem(e, dataField.fieldCost) }}
                        required={
                          dataField.required
                            ? true
                            : false
                        }
                      >
                        {(() => {
                          let rows = [];
                          for (let i = 0; i <= dataField.fieldMax; i++) {
                            rows.push(<option value={i} key={i}>{i}</option>);
                          }
                          return rows;
                        })()}                               </select>
                      {/* <div className0="ps-3">{editRegistration && editRegistration[dataField.fieldName] ? editRegistration[dataField.fieldName + "Cost"] :""}</div> */}
                    </div>
                  </div>
                  </div>


                  {/* <div id="customCard-container" className={adyenLoaded ? "d-block" : "d-none"}>
                    <div className="col-12">
                      <h3>Payment Details</h3>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label>Card Number *</label>
                        <div className="form-control" data-cse="encryptedCardNumber"></div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label>Expiration Date *</label>
                            <div className="form-control" data-cse="encryptedExpiryDate"></div>
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label>CVV/CVC *</label>
                            <div className="form-control" data-cse="encryptedSecurityCode"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </>) : (<></>)}

                {dataField.fieldType == "Numberbox" ? (
                  <div className={dataField.fieldFullRow ? "col-md-12" : (dataField.fieldColumns ? "col-md-" + dataField.fieldColumns : "col-md-" + 6) + " " + (dataField.fieldColumnsMobile ? "col-" + dataField.fieldColumnsMobile : "col-" + 12)}>                    <div className="form-group">
                    <label className="form-label" htmlFor={dataField.fieldName}>
                      {dataField.fieldLabel}{" "}
                      {dataField.required
                        ? "*"
                        : ""}
                    </label>
                    <div>{dataField.fieldDescription}</div>
                    <input
                      type="number"
                      id={dataField.fieldName}
                      name={dataField.fieldName}
                      min={dataField.fieldMin}
                      max={dataField.fieldMax}
                      step={dataField.fieldStep}
                      value={editRegistration ? editRegistration[dataField.fieldName] : ""}
                      onChange={handleChange}
                      className="form-control"
                      required={
                        dataField.required
                          ? true
                          : false
                      }
                    />
                  </div>

                  </div>) : (<></>)}
                {dataField.fieldType == "Dropdown" ? (
                  <div className={dataField.fieldFullRow ? "col-md-12" : (dataField.fieldColumns ? "col-md-" + dataField.fieldColumns : "col-md-" + 6) + " " + (dataField.fieldColumnsMobile ? "col-" + dataField.fieldColumnsMobile : "col-" + 12)}>
                    <div className={"form-group"}>
                      <label className="form-label" htmlFor="dlexpiration">
                        {dataField.fieldLabel}{" "}
                        {dataField.required
                          ? "*"
                          : ""}
                      </label>
                      <select
                        className="form-control"
                        id={dataField.fieldName}
                        name={dataField.fieldName}
                        value={editRegistration ? editRegistration[dataField.fieldName] : ""}
                        onChange={(e) => {
                          setEditRegistration({
                            ...editRegistration,
                            [e.target.name]: e.target.value,
                          });
                        }}
                        required={
                          dataField.required
                            ? true
                            : false
                        }
                      >
                        <option value=""></option>
                        {dataField?.fieldOptions?.split(';').map((opt, index) => (
                          <option value={opt}>
                            {opt}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>) : (<></>)}
                {dataField.fieldType == "Checkbox" ? (
                  <div className={dataField.fieldFullRow ? "col-md-12" : (dataField.fieldColumns ? "col-md-" + dataField.fieldColumns : "col-md-" + 6) + " " + (dataField.fieldColumnsMobile ? "col-" + dataField.fieldColumnsMobile : "col-" + 12)}>

                    <div className={"form-group"}>
                      <label className="checkbox-container">
                        {dataField.fieldLabel}

                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setEditRegistration({
                              ...editRegistration,
                              [e.target.name]: e.target.checked,
                            });
                          }}
                          id={dataField.fieldName + index}
                          name={dataField.fieldName}
                          checked={editRegistration && editRegistration[dataField.fieldName] ? editRegistration[dataField.fieldName] : ""}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>




                  </div>) : (<></>)}
                {!dataField.fieldType ? (
                  <div className={dataField.fieldFullRow ? "col-md-12 d-none d-md-flex" : "col-md-6 d-none d-md-flex"}>
                    <div>&nbsp;</div>
                  </div>) : (<></>)}
              </>
            ))}
          </div>
          {eventData?.allowPushToCap &&
            <>
              <div className="row align-items-center">
                <div className="col-md-12">
                  <div className="row py-4">
                    <div className="col-auto">
                      <div className="form-group">
                        <label className="checkbox-container mb-0">
                          <input
                            type="checkbox"
                            onChange={(e) => {
                              setEditRegistration({
                                ...editRegistration,
                                [e.target.name]: e.target.checked,
                              });
                            }}
                            id="dealer_optin"
                            name="dealer_optin"
                            checked={editRegistration?.dealer_optin || false}
                            value={editRegistration?.dealer_optin || false}
                          />
                          <span className="checkmark"></span>
                        </label>


                      </div>
                    </div>
                    <div className="col">Would you like to be contacted by your local Porsche Center to setup a test drive?</div>
                  </div>
                </div>

              </div>

              <div className="row">
                <div
                  className="col-md"
                >
                  <div className={"form-group"}>
                    <label className="form-label" htmlFor="description">
                      Purchase Time Frame{" "}
                      {editRegistration?.dealer_optin
                        ? "*"
                        : ""}
                    </label>
                    <select
                      className="form-control"
                      id="purchaseTimeFrame"
                      name="purchaseTimeFrame"
                      onChange={(e) => {
                        setEditRegistration({
                          ...editRegistration,
                          purchaseTimeFrame: e.target.value,
                        });
                      }}
                      value={editRegistration?.purchaseTimeFrame}
                      required={
                        editRegistration?.dealer_optin
                          ? true
                          : false
                      }
                    >
                      <option value=""></option>
                      <option value="id_ao1_timeframe">
                        0 - 3 months
                      </option>
                      <option value="id_ao2_timeframe">
                        3 - 6 months
                      </option>
                      <option value="id_ao3_timeframe">
                        6 - 9 months
                      </option>
                      <option value="id_ao4_timeframe">
                        9 - 12 months
                      </option>
                      <option value="id_ao5_timeframe">
                        12 months+
                      </option>             </select>
                  </div>
                </div>
                <div
                  className="col-md"
                >
                  <div className={"form-group"}>
                    <label className="form-label" htmlFor="modelInterest">
                      Model Interest{" "}
                      {editRegistration?.dealer_optin
                        ? "*"
                        : ""}
                    </label>
                    <select
                      className="form-control"
                      id="modelInterest"
                      name="modelInterest"
                      onChange={(e) => {
                        setEditRegistration({
                          ...editRegistration,
                          modelInterest: e.target.value,
                        });
                      }}
                      value={editRegistration?.modelInterest}
                      required={
                        editRegistration?.dealer_optin
                          ? true
                          : false
                      }
                    >
                      <option value=""></option>
                      <option value="id_ao1_primint">Boxster</option>
                      <option value="id_ao7_primint">918 Spyder</option>
                      <option value="id_ao2_primint">Cayman</option>
                      <option value="id_ao5_primint">Panamera</option>
                      <option value="id_ao8_primint">Macan</option>
                      <option value="id_ao8_primint">Macan Electric</option>
                      <option value="id_ao4_primint">Cayenne</option>
                      <option value="id_ao3_primint">911</option>
                      <option value="id_ao9_primint">Taycan</option>
                      {/* {eventData.modelsSelected?.map((dlr, index) => (
                        <option key={index} value={dlr.model}>
                          {dlr.description}
                        </option>
                      ))} */}
                    </select>
                  </div>
                </div>

              </div>
            </>
          }
          <div className="row">
            <div
              className={
                eventData.dataFields?.dealer?.visible ? "col-md" : "d-none"
              }
            >
              {dealersSelected && dealersSelected.length > 1 &&
                <div className={"form-group"}>
                  <label className="form-label" htmlFor="dealer">
                    Preferred Dealer{" "}
                    {eventData.dataFields?.dealer?.visible &&
                      eventData.dataFields?.dealer?.required
                      ? "*"
                      : ""}
                  </label>
                  <select
                    className="form-control"
                    id="dealer"
                    name="dealer"
                    onChange={(e) => {
                      setEditRegistration({
                        ...editRegistration,
                        dealer: e.target.value,
                      });
                    }}
                    value={editRegistration?.dealer}
                    required={
                      eventData.dataFields?.dealer?.visible &&
                        eventData.dataFields?.dealer?.required
                        ? true
                        : false
                    }
                  >
                    <option value=""></option>
                    {dealersSelected?.map((dlr, index) => (
                      <option key={index} value={dlr.code}>
                        {dlr.name}
                      </option>
                    ))}
                  </select>
                </div>
              }
              {dealersSelected && dealersSelected.length == 1 &&
                <div className={"form-group"}>
                  <label className="form-label" htmlFor="dealer">
                    Preferred Dealer
                  </label>
                  <div className="">{dealersSelected[0].name}</div>
                </div>
              }
            </div>
            <div
              className={
                eventData.dataFields?.experience?.visible ? "col-md" : "d-none"
              }
            >
              <div className={"form-group"}>
                <label className="form-label" htmlFor="experience">
                  Experience with Porsche{" "}
                  {eventData.dataFields?.experience?.visible &&
                    eventData.dataFields?.experience?.required
                    ? "*"
                    : ""}
                </label>
                <select
                  className="form-control"
                  id="experience"
                  name="experience"
                  onChange={(e) => {
                    setEditRegistration({
                      ...editRegistration,
                      experience: e.target.value,
                    });
                  }}
                  value={editRegistration?.experience}
                  required={
                    eventData.dataFields?.experience?.visible &&
                      eventData.dataFields?.experience?.required
                      ? true
                      : false
                  }
                >
                  <option value=""></option>
                  <option value="A1">
                    I’ve never driven a Porsche, and not familiar with the Brand
                  </option>
                  <option value="A2">
                    I’ve never driven a Porsche, but I’m familiar with the Brand
                  </option>
                  <option value="A3">
                    I’ve driven a Porsche, but never owned a Porsche
                  </option>
                  <option value="A4">
                    I’ve owned a Porsche, but do not currently own
                  </option>
                  <option value="A5">I currently own a Porsche</option>
                </select>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">

              {editRegistration?.totalCost ? (
                <div className="pb-4">
                  <hr />
                  <h4 className="bold-font">Checkout Summary</h4>
                  <div className="row pb-4">
                    <div className="col-md-8 col-6"></div>
                    <div className="col-md-2 col-3">Qty</div>
                    <div className="col-md-2 col-3">Cost</div>
                  </div>
                  {eventData?.additionalFields?.map((item, index) => (
                    <>

                      {editRegistration[item.fieldName] && editRegistration[item.fieldName] > 0 ? (
                        <div className="row">
                          <div className="col-md-8 col-6">{item.fieldLabel}</div>
                          <div className="col-md-2 col-3">{editRegistration[item.fieldName]}</div>
                          <div className="col-md-2 col-3">{editRegistration[item.fieldName + "Cost"]}</div>
                        </div>
                      ) : (<></>)}
                    </>
                  ))}


                  <hr />
                  {eventData?.paymentTax > 0 ? <div>
                    <div className="row">
                      <div className="col-md-8 col-6"></div>
                      <div className="col-md-2 col-3 bold-font text-end">Subtotal:</div>
                      <div className="col-md-2 col-3">{editRegistration?.subCost}</div>
                    </div>
                    <div className="row">
                      <div className="col-md-8 col-6"></div>
                      <div className="col-md-2 col-3 bold-font text-end">Tax({eventData?.fieldTax}%):</div>
                      <div className="col-md-2 col-3">{editRegistration?.taxCost}</div>
                    </div>

                  </div> : <></>}
                  <div className="row">
                    <div className="col-md-8 col-6"></div>
                    <div className="col-md-2 col-3 bold-font text-end">Total:</div>
                    <div className="col-md-2 col-3">{editRegistration?.totalCost}</div>
                  </div>
                </div>) : (<></>)}
            </div>
            {eventData?.paymentType === '' ? <div className="col-12">
              <div className="alert alert-warning">Payment for this registration will be handled manually.</div>
            </div> : <div className="col-12"></div>}

          </div>
          <div className="row">
            <div className="col-md-6">
              <div className={"form-group"}>
                <label className="form-label" htmlFor="title">
                  Status *
                </label>
                <select
                  className="form-control"
                  id="status"
                  name="status"
                  onChange={(e) => {
                    setEditRegistration({
                      ...editRegistration,
                      status: e.target.value,
                    });
                  }}
                  value={editRegistration?.status}
                  required={true}
                >
                  <option value="Pending">Pending</option>
                  <option value="Registered">Registered</option>
                  <option value="Checked-In">Checked-In</option>
                  <option value="Waitlist">Waitlist</option>
                  <option value="Cancelled">Cancelled</option>
                </select>
              </div>
              {editRegistration?.status == "Checked-In" &&
                <div className="alert alert-danger fs-lg">Guest has already been checked-in</div>
              }
            </div>            <div className="col-md-6">
              {eventData?.additionalFields && eventData?.additionalFields.length > 0 && arraySearch(eventData?.additionalFields, 'CommerceItem') >= 0 ?
                <div className={"form-group"}>
                  <label className="form-label" htmlFor="title">
                    Payment Status *
                  </label>
                  <select disabled={true}
                    className="form-control"
                    id="adyenPaymentStatus"
                    name="adyenPaymentStatus"
                    onChange={(e) => {
                      setEditRegistration({
                        ...editRegistration,
                        adyenPaymentStatus: e.target.value,
                      });
                    }}
                    value={editRegistration?.adyenPaymentStatus}
                    required={true}
                  >
                    <option value="Pending">Pending</option>
                    <option value="Paid">Paid</option>
                    <option value="Waived">Waived</option>
                    <option value="Refunded">Refunded</option>
                  </select>
                </div>
                : <></>}
              {editRegistration?.adyenResultCode &&
                <div><label>Adyen Result:</label> {editRegistration?.adyenResultCode}</div>
              }
              {editRegistration?.adyenRef &&
                <div><label>Adyen Ref #:</label> {editRegistration?.adyenRef}</div>
              }
              {editRegistration?.adyenRefDate &&
                <div><label>Adyen Ref Date:</label> {moment(editRegistration?.adyenRefDate).format("MM/DD/YYYY h:mm A")}</div>
              }
              {editRegistration?.adyenLink && !editRegistration?.adyenRef &&
                <div><label>Adyen Link:</label> <a href={editRegistration?.adyenLink} target="_blank">{editRegistration?.adyenLink}</a></div>
              }
              {editRegistration?.adyenLinkExpireDate && !editRegistration?.adyenRef &&
                <div><label>Adyen Link Expiration:</label> {moment(editRegistration?.adyenLinkExpireDate).format("MM/DD/YYYY h:mm A")}</div>
              }
              {editRegistration?.adyenLinkId &&
                <div><label>Adyen Link Id:</label> {editRegistration?.adyenLinkId}</div>
              }
              {editRegistration?.adyenLink && !editRegistration?.adyenRef &&
                <div>  <button className="btn btn-theme" onClick={handleDeletePaymentLink}>
                  Delete Payment Link
                </button></div>
              }
              {eventData?.additionalFields && eventData?.additionalFields.length > 0 && arraySearch(eventData?.additionalFields, 'CommerceItem') >= 0 && !editRegistration?.adyenLink && !editRegistration?.adyenRef && (!editRegistration?.adyenPaymentStatus || editRegistration?.adyenPaymentStatus === "Pending") &&
                <div className="pb-2"><button className="btn btn-theme" onClick={handleCreatePaymentLink}>
                  Create Payment Link
                </button></div>
              }
              {eventData?.additionalFields && eventData?.additionalFields.length > 0 && arraySearch(eventData?.additionalFields, 'CommerceItem') >= 0 && !editRegistration?.adyenLink && !editRegistration?.adyenRef && editRegistration?.adyenPaymentStatus !== "Waived" &&
                <div>  <button className="btn btn-theme" onClick={handleWaivedPaymentLink}>
                  Waive Payment
                </button></div>
              }
              {editRegistration?.adyenRef && editRegistration?.adyenPaymentStatus === "Paid" &&
                <div>  <button className="btn btn-default" onClick={handleRefundedPaymentLink}>
                  Mark Refunded
                </button></div>
              }
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="notes">
                  Notes{" "}
                  {eventData.dataFields?.notes?.visible &&
                    eventData.dataFields?.notes?.required
                    ? "*"
                    : ""}
                </label>
                <textarea
                  type="text"
                  id="notes"
                  name="notes"
                  value={editRegistration?.notes || ""}
                  onChange={handleChange}
                  className="form-control"

                ></textarea>
              </div>
              <div><label>Created: </label>{editRegistration?.createdAt ? moment(editRegistration?.createdAt).format("MM/DD/YYYY h:mm A") : "N/A"}</div>
              <div><label>Updated: </label>{editRegistration?.updatedAt ? moment(editRegistration?.updatedAt).format("MM/DD/YYYY h:mm A") : "N/A"}</div>
              <div><label>Expiration: </label>{editRegistration?.expiration ? moment(editRegistration?.expiration).format("MM/DD/YYYY h:mm A") : "N/A"}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={eventData.allowWaivers ? "waivers" : "waivers d-none"}>
        <div
          className={
            completeWaiver || (editRegistration && editRegistration.hasWaiver)
              ? "d-none"
              : ""
          }
        >
          <button
            className="btn btn-link"
            onClick={() => {
              setCompleteWaiver(true);
            }}
          >
            Complete Waiver
          </button>
          <button disabled className="btn btn-link ml-3" onClick={sendWaiver}>
            Send Waiver
          </button>
        </div>
        <div
          className={
            editRegistration && !editRegistration.hasWaiver ? "d-none" : ""
          }
        >
          <a
            className="btn btn-link"
            onClick={(e) => {
              downloadWaiver(e, waiverUrl);
            }}
            href={waiverUrl}
          >
            Download Waiver
          </a>
          <button className="btn btn-link ml-3" onClick={deleteWaiver}>
            Delete Waiver
          </button>
        </div>
        <div
          className={
            completeWaiver && editRegistration && !editRegistration.hasWaiver
              ? "waiver-form"
              : "d-none"
          }
        >
          <h5>Complete Waiver</h5>
          <div
            id="waiver-print"
            className="hidden"
            style={{
              fontSize: "12px !important",
              fontFamily: "Arial !important",
              maxWidth: "560px !important",
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(eventData.waiverCopy?.content),
              }}
            ></div>
            <div>Signature:</div>
            <div style={{ borderBottom: "2px solid #000000" }}>
              <img src={waiverSigUrl} alt="Waiver Signature" />
            </div>
            <div style={{ borderBottom: "2px solid #000000,padding-top:40px" }}>
              Printed Name: {editRegistration?.firstName}{" "}
              {editRegistration?.lastName}
            </div>
            <div style={{ borderBottom: "2px solid #000000,paddingTop:40px" }}>
              Date: {moment().format("MM/DD/YYYY")}
            </div>
            <div className="text-center">
              <div></div>
            </div>
          </div>
          <div
            className="py-3"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(eventData.waiverCopy?.content),
            }}
          ></div>
          <div>Signature</div>
          <div className="sig-container">
            <SignatureCanvas
              ref={sigCanvas}
              onBegin={() => { setSavingSignature(true) }}
              onEnd={saveCanvas}
              canvasProps={{
                width: scr.width,
                height: scr.height,
                className: "sigCanvas",
              }}
            />
          </div>
          <div className="">
            <button className="btn btn-link" onClick={clearCanvas}>
              Clear Signature
            </button>
            <button
              className="btn btn-link ml-3"
              onClick={() => {
                setCompleteWaiver(false);
                sigCanvas.current.clear();
              }}
            >
              Cancel Waiver
            </button>
          </div>
        </div>
      </div>
      <div>
        {uploadInfo &&
          <div className="py-5">
            <div><strong>Submission Details</strong></div>
            <div>Total Files: {uploadInfo.length}</div>
            {uploadInfo?.map((f, index) => (
              <div className="row hover-row py-2" key={index}>
                <div className="col"><a href={f.url} target="_blank">{f.name}</a></div>
                <div className="col-auto"><i className="sm" onClick={() => {
                  var fileRef = firebase.storage().refFromURL(f.url);
                  fileRef.delete().then(function () {

                    // File deleted successfully
                    updateUploads();
                  }).catch(function (error) {
                    // Some Error occurred
                  });
                }}>
                  <DeleteIcon />
                </i></div>
              </div>
            ))}
            <div className="py-2"></div>
          </div>
        }
        {eventData.allowUpload &&
          <div className="py-5">
            <Dropzone onDrop={onDrop} accept="application/pdf,image/*" maxFiles={5} maxSize={26214400}>
              {({ getRootProps, getInputProps, acceptedFiles }) => {
                return (
                  <div className="">
                    <aside>
                      <div className={acceptedFiles && acceptedFiles.length > 0 ? "py-3 bold-font" : "d-none"}>Uploaded Files</div>
                      <ul>
                        {acceptedFiles.map(file => (
                          <li key={file.path}>
                            {file.path} - {file.size} bytes
                          </li>
                        ))}
                      </ul>
                    </aside>
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop submission files here, or click to select files</p>

                    </div>

                  </div>
                );
              }}
            </Dropzone>
          </div>
        }
      </div>

      {editRegistration?.status === "Registered" && (
        <div>
          <div className="pt-5 pb-2"></div>
          <div className="row">
            <div className="col-auto">
              <button className="btn btn-theme" onClick={handleCheckInAndClose}>
                Check-In & Close
              </button>
            </div>

            <div className="col text-end">&nbsp;</div>
            <div className="col-auto">
              <button className="btn btn-theme" onClick={handleCheckInAndNew}>
                Check-In & New
              </button>
            </div>
            {eventData?.allowGuests && !editRegistration?.isGuest &&
              <div className="col-auto">
                <button className="btn btn-theme" onClick={handleCheckInAndGuest}>
                  Check-In & Add Guest
                </button>
              </div>
            }
            <div className="col text-end">&nbsp;</div>
          </div>
          <div className="pt-5 pb-2"></div>

        </div>
      )}
      <div>
        {(eventData.allowConfirmationEmail || eventData.reminderEmail) && (<>
          <div className="pb-3">
            {previewEmailHtml &&
              <button className="btn btn-link" onClick={() => { setPreviewEmailHtml(null); }}>
                Hide Email Preview
              </button>}

            {!previewEmailHtml &&
              <button className="btn btn-link" onClick={previewEmail}>
                Preview Email
              </button>
            }
            <div className={previewEmailHtml ? "py-5" : "d-none"}>
              <iframe className="previewEmail" srcDoc={previewEmailHtml} sandbox="allow-same-origin" id="htmlPreviewFrame" name="htmlPreviewFrame" />
            </div>
          </div>
        </>)}
        {eventData.allowConfirmationEmail && (<>

          <div>
            <label className="checkbox-container">
              Send{editRegistration?.status ? " " + editRegistration?.status : ""}  Confirmation Email
              <input
                type="checkbox"
                onChange={(e) => {
                  setSendEmailCheck(e.target.checked);
                }}
                id="sendEmailCheck"
                name="sendEmailCheck"
                checked={sendEmailCheck}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </>)}

      </div>
      <div>
        {eventData.reminderEmail && (
          <label className="checkbox-container">
            Send Reminder Email
            <input
              type="checkbox"
              onChange={(e) => {
                setSendEmailCheck(e.target.checked);
              }}
              id="sendEmailCheck"
              name="sendEmailCheck"
              checked={sendEmailCheck}
            />
            <span className="checkmark"></span>
          </label>
        )}
      </div>
      {savingSignature &&
        <div className="alert alert-danger">Waiver is being saved...</div>
      }
      {!savingSignature &&
        <div className="row">
          <div className="col-auto">
            <button className="btn btn-theme" onClick={handleSubmitNone}>
              Save
            </button>
          </div>
          <div className="col-auto">
            <button className="btn btn-theme" onClick={handleSubmitAndClose}>
              Save & Close
            </button>
          </div>
          <div className="col-auto">
            <button className="btn btn-theme" onClick={handleSubmitAndNew}>
              Save & New
            </button>
          </div>
          {eventData?.allowGuests && !editRegistration?.isGuest &&
            <div className="col-auto">
              <button className="btn btn-theme" onClick={handleSubmitAndGuest}>
                Save & Add Guest
              </button>
            </div>
          }
          <div className="col-auto">
            <button
              className="btn btn-default"
              onClick={() => history("/manage/registrations")}
            >
              Close
            </button>

          </div>
          <div className="col text-end">
            <button
              className="btn btn-link"
              onClick={() =>
                window.confirm("Are you sure to delete this record?")
                  ? deleteRegistration()
                  : console.log("action cancelled by user.")
              }
            >
              <i className="sm">
                <DeleteIcon />
              </i>
            </button>
          </div>
        </div>
      }

      <div className={"form-group pt-5"}>
        <label className="checkbox-container">
          Do not send lead data.
          <input
            disabled={editRegistration?.leadSent}
            type="checkbox"
            onChange={(e) => {
              setEditRegistration({
                ...editRegistration,
                disableLead: e.target.checked,
              });
            }}
            id="disableLead"
            name="disableLead"
            checked={editRegistration?.disableLead || false}
          />
          <span className="checkmark"></span>
        </label>
      </div>

      <div className={"form-group pb-3"}>
        <label className="checkbox-container">
          Data sent to C@P
          <input
            disabled={user.email.indexOf("core12.com") > -1 ?  false : true}
            type="checkbox"
            onChange={(e) => {
              setEditRegistration({
                ...editRegistration,
                leadSent: e.target.checked,
              });
            }}
            id="leadSent"
            name="leadSent"
            checked={editRegistration?.leadSent || false}
          />
          <span className="checkmark"></span>
        </label>
      </div>

      <div className="py-3"></div>
    </div>
  );
};
export default Registration;
