import React, { useEffect, useContext, useState, useReducer, useCallback } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { firebaseAuth } from "../../../providers/AuthProvider";
import firebase from "firebase/compat/app";
import { db } from "../../../firebase";
import "firebase/functions";
import { DeleteIcon, EditIcon } from "../../../Constants";
import "../../../assets/css/groups.css";
import moment from "moment";
import { EventDataContext } from "../EventDataContext"
import { useDrag, useDrop } from 'react-dnd'
import { EventCard } from './EventCard';
// import * as Sentry from "@sentry/react";
const Events = () => {
  const navigate = useNavigate();

  const { user } = useContext(firebaseAuth);
  const newEvent = {
    id: db.collection('events').doc().id, name: 'New Event', email: 'info', status: 'Active', sortOrder: 0,
    dataFields: {
      dealer: { visible: false, required: false }, experience: { visible: true, required: false }, purchaseTimeFrame: { visible: true, required: false }, firstName: { visible: true, required: true }, title: { visible: false, required: false },
      address1: { visible: false, required: false }, city: { required: false, visible: false }, dealer: { visible: false }, email: { visible: true, required: true },lastName: { visible: true, required: true },
      modelInterest: { visible: false }, phone: { visible: false }, postal: { required: true, visible: true }, purchaseTimeFrame: { visible: true }, state: { required: false, visible: false }
    }
  }

  const reducer = (state, payload) => ({ ...state, ...payload });
  const { eventData, setEventData } = useContext(EventDataContext);
  const [searchText, setSearchText] = useState("");
  const [showArchive, setShowArchive] = useState(false);
  const [initDone, setInitDone] = useState(false);
  const [data, setData] = useReducer(reducer, {
    items: [],
    isFetching: false,
    message: null,
  });
  const moveCard = useCallback((dragIndex, hoverIndex) => {
    let tmpItems = data.items;
    let dragCard = data.items[dragIndex];
    tmpItems.splice(dragIndex, 1);
    console.log(dragCard);
    //   Array.prototype.Insert = function (item, before) {
    //     if (!item) return;
    //     if (before == null || before < 0 || before > this.length - 1) {
    //         this.push(item);
    //         return;
    //     }
    //     this.splice(before, 0,item );
    // }
    // tmpItems.Insert(dragCard,hoverIndex);
    tmpItems.splice(hoverIndex, 0, dragCard);
    console.log(tmpItems);
    setData({ ...data, items: tmpItems, updateSort: true });


    // setData(data.items, {
    //     $splice: [
    //         [dragIndex, 1],
    //         [hoverIndex, 0, dragCard],
    //     ],
    // }));
  }, [data.items]);


  const fetch = async (items) => {
    setData({
      items: data.items,
      isFetching: true,
      message: null,
    });
    let tmpItems = [];
    try {
      await firebase.auth();
    let getEventData = await firebase.functions().httpsCallable("app/getEventData")
    let result = await getEventData({ userEmail: user.email, userRole: user.role, showArchive: showArchive });
    setInitDone(true);
    let resultData = result.data;
    // console.log(resultData[0].createdAt);
    // console.log('---')
    setData({
      items: resultData,
      isFetching: false,
      message: null,
    });
  }
    catch(error) {
      console.log(error);
    }
    let tmpFeaturedItems = [];
    let tmpArchivedItems = [];

  };
  useEffect(() => {
    if (user.isAuthenticated) {
      
      fetch();
      console.log('fetching events');
    } else {
      navigate('/manage/sign-in')

    }

  }, []);
  useEffect(() => {
    if (user.isAuthenticated && initDone) {
      fetch();
      console.log('fetching events');
      console.log('archiveToggle');
    } 
  }, [showArchive]);
  useEffect(() => {
    if (data.updateSort) {
      data.items.map((item, i) => {
        if (item.listedEvent && item.status == "Active")
          item.sortOrder = i;
        else if (!item.listedEvent && item.status == "Active")
          item.sortOrder = i + 10000;
        else if (item.status == "Archived")
          item.sortOrder = i + 20000;
        db.collection("events").doc(item.id).set(item);
      });


      setData({ ...data, updateSort: false });
    }

  }, [data]);
  function Fetching(props) {
    if (data.isFetching) {
      return <div className="alert alert-danger">Loading items.</div>;
    } else {
      return null;
    }
  }
  const deleteItem = () => { };
  // if (!user.isAuthenticated) {

  //   return <Navigate to="/manage/sign-in" />;
  // }

  return (
    <div className="container" id="events">
      <h1>Events</h1>
      <div className="py-2"></div>
      <div className="row">
        <div className="col">
          <div className="form-group">
            <label>Search</label>
            <input type="text" name="searchText" value={searchText} onChange={(e) => { setSearchText(e.target.value) }} className="form-control" />
          </div>
        </div>
        {user.role < 2 &&
          <div className="text-end col">
            <NavLink to={"/manage/event"} onClick={() => { localStorage.setItem('eventData', JSON.stringify(newEvent)); setEventData(newEvent) }} className="btn btn-theme">
              New Event
            </NavLink><br />
            <button
              onClick={() => {
                setShowArchive(!showArchive);
              }}
              className="btn btn-link"
            >
              {showArchive ? "Hide Archived Events" : "Show Archived Events"}
            </button>
          </div>
        }
      </div>
      <div className="py-3"></div>
      <Fetching />
      <h4 className="fs-xxl bold-font">Featured Events</h4>
      <div className="row">
        {data.items.map((g, i) => {

          if ((!searchText || g.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1) && g.status == "Active" && g.listedEvent) {
            return (
              <div key={g.id} className="col-md-4 col-lg-3 pb-4">
                <EventCard item={g} key={i} id={i} index={i} moveCard={moveCard} updateData={() => { fetch(); }} />
              </div>
            )
          }

        })}
      </div>

      <div className="py-3"></div>
      <h4 className="fs-xxl bold-font">Other Events</h4>
      <div className="row">
      {data.items.sort((a,b) => moment(a.startDate).format("YYYYMMDD") > moment(b.startDate).format("YYYYMMDD")).map((g, i) => {

          if ((!searchText || g.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1) && g.status == "Active" && !g.listedEvent) {
            return (
              <div key={g.id} className="col-md-4 col-lg-3 pb-4">
                <EventCard item={g} key={i} id={i} index={i} moveCard={moveCard} updateData={() => { fetch(); }} />
              </div>

            )
          }
        })}
      </div>
      <div className="py-3"></div>
      {showArchive &&
        <h4 className="fs-xxl bold-font">Archived Events</h4>
      }
      <div className="row">
      {data.items.sort((a,b) => moment(a.startDate).format("YYYYMMDD") > moment(b.startDate).format("YYYYMMDD")).map((g, i) => {

          if ((!searchText || g.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1) && g.status == "Archived") {
            return (
              <div key={g.id} className="col-md-4 col-lg-3 pb-4">
                <EventCard item={g} key={i} id={i} index={i} moveCard={moveCard} updateData={() => { fetch(); }} />
              </div>
            )
          }
        })}
      </div>
    </div>
  );
};

export default Events;
